<template>
  <div></div>
</template>

<script>
import { tableLogin } from "@/store/api";
import { saveLocalStorage } from "@/store/service";
export default {
  data() {
    return {
      tableId: this.$route.params.table,
    };
  },
  mounted() {
    this.login();
  },
  methods: {
    login() {
      tableLogin(this.tableId).then((res) => {
        localStorage.removeItem("cart");
        saveLocalStorage("userData", JSON.stringify(res.data.data));
        saveLocalStorage("userDataVerify", "true");
        this.$router.push("/shop");
      });
    },
  },
};
</script>
